import React from "react";
import IMG from "../img";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDroplet, faSprayCan, faFlask, faChartColumn, faChartPie, faChartLine, faEnvelope, faHouse, faCoins, faHammer, faPlay, faUsers, faFlag } from '@fortawesome/free-solid-svg-icons'

const droplet = <FontAwesomeIcon icon={faDroplet} fixedWidth />
const sprayCan = <FontAwesomeIcon icon={faSprayCan} fixedWidth />
const flask = <FontAwesomeIcon icon={faFlask} fixedWidth />
const chart = <FontAwesomeIcon icon={faChartColumn} fixedWidth />
const chartPie = <FontAwesomeIcon icon={faChartPie} fixedWidth />
const chartLine = <FontAwesomeIcon icon={faChartLine} fixedWidth />
const mail = <FontAwesomeIcon icon={faEnvelope} fixedWidth />
const house = <FontAwesomeIcon icon={faHouse} fixedWidth />
const coins = <FontAwesomeIcon icon={faCoins} fixedWidth />
const hammer = <FontAwesomeIcon icon={faHammer} fixedWidth />
const play = <FontAwesomeIcon icon={faPlay} fixedWidth />
const person = <FontAwesomeIcon icon={faUsers} fixedWidth />
const flag = <FontAwesomeIcon icon={faFlag} fixedWidth />

function Content(){


    return(
        <div className="contentWrapper">
        <div id="transport">
            <div className="header">
                <h1>We Specialize In</h1>
                <div className="transportContent">
                    <h2>International trading</h2>
                    <p>{droplet} petroleum products</p>
                    <p>{sprayCan} household chemicals</p>
                    <p>{flask} nanotechnology</p>
                </div>
            </div>
            <div className="transportImg">
                <img src={IMG.transport}></img>
            </div>
        </div>
        <div id="merchanting">
            <div className="merchantingIMG">
                <img src={IMG.merchanting}></img>
            </div>
            <div className="merchantingContent">
                <h2>Merchanting</h2>
                <p>{chart} market analysis</p>
                <p>{chartPie} competitor analysis</p>
                <p>{chartLine}price level analysis</p>
                <p>{mail} mailing and bidding</p>
            </div>
        </div>
        <div id="development">
            <div className="developmentImg">
                <img src={IMG.development}></img>
            </div>
            <div className="developmentContent">
                <h2>Development investments</h2>
                <p>{house} primary and secondary market</p>
                <p>{coins} sale of construction materials</p>
                <p>{hammer} constructions</p>
            </div>
        </div>
        <div id="advertising">
            <div className="advertisingImg">
                <img src={IMG.advertising}></img>
            </div>
            <div className="advertisingContent">
                <h2>Advertising and Marketing</h2>
                <p>{play} media planning</p>
                <p>{chart} market research and analysis</p>
                <p>{person} public relations</p>
                <p>{flag} preparation of the product for the market </p>
            </div>
        </div>
        </div>
    );
}

export default Content;