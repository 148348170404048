const IMG = {
    logo: require('./logo/logo.png'),
    transport: require('./type/transportIMG.png'),
    drop: require('./type/dropIcon.png'),
    merchanting: require('./type/merchanting.png'),
    development: require('./type/development.png'),
    advertising: require('./type/advertising.png'),
    contact: require('./type/contact.png'),
    menu: require('./logo/menu.png')
}

export default IMG