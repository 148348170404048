import React from "react";
import IMG from "../img";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const house = <FontAwesomeIcon icon={faHouse} fixedWidth size="1.82vw" />
const mail = <FontAwesomeIcon icon={faEnvelope} fixedWidth size="1.82vw"/>

function Contact() {
    return(
        <div className="contactImg" style={{backgroundImage: `url(${IMG.contact})`}}>
            <div id="contact">
                <h2>Contact</h2>
                <p>{house}<br/> Inomenon Ethnon 48 <br/> Guricon House<br/> 6042 Larnaca<br/> Cyprus</p>
                <div className="contactTextP">
                    <p className="contactTextPsec">{mail}<br/>office.oreonaLTD@gmail.com</p>
                    <p className="contactTextPsec">office.oreonaLTD@gmail.com</p>
                </div>
                <div id="map">
                    <h2>Map</h2>
                </div>
            </div>
        </div>
    )
}

export default Contact;