import React from "react";
import IMG from '../img/index.js';
import AnchorLink from 'react-anchor-link-smooth-scroll'

function Header() {
    return(
        <div className="wrapperHeader">
            <div className="logo">
                <img src={IMG.logo}></img>
            </div>
            <div className="menu" style={{backgroundImage: `url(${IMG.menu})`}}>
                <ul>
                    <li><AnchorLink offset='100' href="#transport">Transport</AnchorLink></li>
                    <li><AnchorLink offset='100' href="#merchanting">Merchanting</AnchorLink></li>
                    <li><AnchorLink offset='100' href="#development">Development</AnchorLink></li>
                    <li><AnchorLink offset='100' href="#advertising">Advertising</AnchorLink></li>
                    <li><AnchorLink offset='100' href="#contact">Contact</AnchorLink></li>
                    <li><AnchorLink offset='100' href="#map">Map</AnchorLink></li>
                </ul>
            </div>
        </div>
    );
}

export default Header;